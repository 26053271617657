import React from 'react';

import { PLAYLIST_STATUS } from '@content-playlist-creation/common/constants';
import PropTypes from 'prop-types';

import * as PERMISSIONS from '../../constants/permissions';
import PLAYER_STATUS from '../../constants/player-status';
import GlobalContext from '../../contexts/GlobalContext';
import closeImg from '../../images/close.svg';
import pauseWhiteImg from '../../images/pause-white.svg';
import playWhiteImg from '../../images/play-white.svg';
import playImg from '../../images/play.svg';
import { isContentCreator, isInstructor, hasPermission, isViewOnly } from '../../utils/auth';
import DropdownButton from '../DropdownButton';
import InOutField from '../InOutField';
import { NotesPanel } from '../NotesPanel';
import ActionMenu from '../search/ActionMenu';

class SongsRow extends React.Component {
	constructor(props) {
		super(props);
		this.getPlaySongButton = this.getPlaySongButton.bind(this);
		this.getSongActions = this.getSongActions.bind(this);
		this.getZoneKey = this.getZoneKey.bind(this);
		this.getInOutVal = this.getInOutVal.bind(this);
		this.onZoneKeyChange = this.onZoneKeyChange.bind(this);
	}

	onZoneKeyChange(zoneKeyId) {
		const { song, handleZoneKeyChange } = this.props;

		handleZoneKeyChange(zoneKeyId, song.playlist_song_id);
	}

	getPlaySongButton() {
		const { currentSong, playerStatus } = this.context;
		const { song, isBlacklisted, handlePlaySong } = this.props;
		const isSongPlaying =
			currentSong &&
			currentSong.song_id.toString() === song.song_id.toString() &&
			playerStatus === PLAYER_STATUS.PLAYING;

		const playImage = song.thumbnail_image || song.image ? playWhiteImg : playImg;

		return (
			<span
				className='d-flex songslist__table__play'
				style={{
					backgroundImage: `url(${song.thumbnail_image || song.image})`,
					backgroundSize: 'cover',
					filter: song.valid && !isBlacklisted ? 'none' : 'opacity(0.5) grayscale(0.75)',
				}}
			>
				{song.valid && !isBlacklisted ? (
					<div className='songslist__song__btn'>
						<img
							src={isSongPlaying ? pauseWhiteImg : playImage}
							alt={isSongPlaying ? 'pause' : 'play'}
							onClick={() => handlePlaySong(song)}
						/>
					</div>
				) : null}
			</span>
		);
	}

	getSongInfo() {
		const { song } = this.props;

		return (
			<div className='songslist__song-data'>
				<div className='songslist__song__name'>
					<b>{song.name}</b>
					{song.explicit ? <span className='songslist__song--explicit'>EXPLICIT</span> : null}
				</div>
				<div className='songslist__song__data-label'>{song.display_artist}</div>
				<div>
					{song.bpm ? `${Math.round(song.bpm)} BPM • ` : ''}
					{song.genre_cleared ? (
						<span>
							{song.genre_cleared}
							{isContentCreator() && (
								<span
									style={
										{ opacity: 0.8 } // allow content creator see the track source genre
									}
								>
									&nbsp;[
									{song.genre || '-'}]
								</span>
							)}
							&nbsp;•&nbsp;
						</span>
					) : null}
					{!song.genre_cleared && song.genre ? `${song.genre} • ` : ''}
					{song.duration ? this.secondsToTime(Math.round(song.duration)) : ''}
				</div>
				<div className='songslist__song__data-note'>
					{song.isrc} • {song.variis_track_id}
				</div>
				<div className='songslist__song__data-note'>
					{song.feed_name}
					{song.pline_year ? ` • ℗ ${song.pline_year}` : ''}
				</div>
			</div>
		);
	}

	getClearedTimestamp() {
		const { showClearedDate, inFolder, song } = this.props;

		if (showClearedDate) {
			return (
				<div
					className={`songslist__cleared-timestamp ${
						isContentCreator() && !inFolder ? 'songslist__cleared-timestamp--cc' : ''
					}`}
				>
					{song.cleared_timestamp}
				</div>
			);
		}
		return null;
	}

	getZoneKey() {
		const { song, zoneKeys, isBlacklisted } = this.props;

		return (
			<span className='songslist__row__zone-transition-io'>
				<div className='songslist__row__zone-transition-io__field songslist__row__zone-transition-io__field--zone-transition'>
					<b>Zone Key</b>
				</div>
				<DropdownButton
					onChange={e => this.onZoneKeyChange(e.value)}
					options={[{ label: 'None', value: null }, ...zoneKeys.map(z => ({ label: z.name, value: z.zone_key_id }))]}
					showArrowIcon
					showFilterIcon={false}
					value={
						zoneKeys && zoneKeys.length > 0 && song.zone_key_id
							? (zoneKeys.filter(z => z.zone_key_id === song.zone_key_id)[0] || {}).name
							: 'None'
					}
					disabled={!song.valid || this.isLocked() || isBlacklisted}
					alignCenter
					menuClassName='songslist__song__dropdown__menu'
				/>
			</span>
		);
	}

	getInOutVal() {
		const { song, handleOutValChange, handleInValChange, isBlacklisted } = this.props;

		return (
			<InOutField
				disabled={!song.valid || isBlacklisted || this.isLocked()}
				inVal={song.in_val}
				outVal={song.out_val}
				duration={song.duration}
				onInValSubmit={inVal => handleInValChange(inVal, song.playlist_song_id)}
				onOutValSubmit={outVal => handleOutValChange(outVal, song.playlist_song_id)}
			/>
		);
	}

	getTransition() {
		const { song, transitions, handleTransitionChange, isBlacklisted } = this.props;

		return (
			<span className='songslist__row__zone-transition-io'>
				<div className='songslist__row__zone-transition-io__field songslist__row__zone-transition-io__field--zone-transition'>
					<b>Transition</b>
				</div>
				<DropdownButton
					disabled={!song.valid || isBlacklisted || this.isLocked()}
					onChange={e => handleTransitionChange(e.value, song.playlist_song_id)}
					options={[
						{ label: 'None', value: null },
						...transitions.map(t => ({ label: t.name, value: t.transition_id })),
					]}
					showArrowIcon
					showFilterIcon={false}
					value={
						transitions && transitions.length > 0 && song.transition_id
							? transitions.filter(t => t.transition_id === song.transition_id)[0].name
							: 'None'
					}
					alignCenter
				/>
			</span>
		);
	}

	getNotes() {
		const { song, inPlaylist, handleUpdateNote } = this.props;

		return (
			<span>
				<div className='songslist__row__notes'>
					<b>Notes</b>
				</div>
				<NotesPanel
					note={song.notes}
					onNoteUpdate={newNote => handleUpdateNote(newNote, inPlaylist ? song.playlist_song_id : song.user_song_id)}
					tooltipHeader='Songs Notes'
					tooltipDetail={song.name}
					isLocked={this.isLocked()}
				/>
			</span>
		);
	}

	getRemoveSongButton() {
		const { playlistStatus, handleRemoveSong, inPlaylist, inFolder, song } = this.props;
		const removableSongInPlaylist =
			inPlaylist && ![PLAYLIST_STATUS.APPROVED, PLAYLIST_STATUS.ARCHIVED].includes(playlistStatus);
		const removableSongInFolder = inFolder && hasPermission([PERMISSIONS.SHARED_FOLDER_CREATOR]);

		if (removableSongInPlaylist || removableSongInFolder) {
			return (
				<div className='songslist__song__btn songslist__song__btn--remove'>
					<img src={closeImg} alt='remove' onClick={() => handleRemoveSong(song)} />
				</div>
			);
		}
		return null;
	}

	getActionsMenuButton() {
		const {
			song,
			isBlacklisted,
			playlists,
			folders,
			handleAddSongToPlaylist,
			handleBlacklistSongISRC,
			handleBlacklistSongVariisID,
			handleAddSongToFolder,
			handleFavouriteSong,
		} = this.props;
		const { openISRCPlayCountModal } = this.context;

		return (
			<ActionMenu
				showSongInfo={false}
				handleAddToPlaylist={(sid, pid) => handleAddSongToPlaylist(song, pid)}
				handleAddISRCSongToBlacklist={() => handleBlacklistSongISRC()}
				handleAddVariisIDSongToBlacklist={() => handleBlacklistSongVariisID()}
				handlePlayCountSong={() => openISRCPlayCountModal(song.isrc)}
				handleAddToFolder={(sid, fid) => handleAddSongToFolder(song, fid)}
				handleAddToFavourites={() => handleFavouriteSong(song)}
				playlists={playlists}
				folders={folders}
				song={{ ...song, is_blacklisted: isBlacklisted }}
			/>
		);
	}

	getSongActions() {
		const { song, isBlacklisted } = this.props;

		return (
			<div className='d-flex flex-direction-column justify-content-around align-items-start mr-3'>
				{!isBlacklisted && song.valid && this.getActionsMenuButton()}
				{this.getRemoveSongButton()}
				{this.getClearedTimestamp()}
			</div>
		);
	}

	secondsToTime = seconds => {
		let time = '';
		const hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds - hours * 3600) / 60);

		// eslint-disable-next-line no-param-reassign
		seconds = seconds - hours * 3600 - minutes * 60;

		if (hours !== 0) {
			time = `${hours}:`;
		}
		if (minutes !== 0 || time !== '') {
			minutes = minutes < 10 && time !== '' ? `0${minutes}` : String(minutes);
			time += `${minutes}:`;
		}
		if (time === '') {
			time = `${seconds}s`;
		} else {
			time += seconds < 10 ? `0${seconds}` : String(seconds);
		}
		return time;
	};

	// a Playlist is locked (read-only) if it is Archived, Invalid, and
	//      if the user is an Instructor and the playlist is Submitted OR Approved OR Rejected
	//      if the user is a Content Creator and has the Content Creator View Only permission
	isLocked() {
		const { playlistStatus } = this.props;

		return (
			[PLAYLIST_STATUS.ARCHIVED, PLAYLIST_STATUS.APPROVED, PLAYLIST_STATUS.INVALID].includes(playlistStatus) ||
			(isInstructor() && [PLAYLIST_STATUS.SUBMITTED, PLAYLIST_STATUS.REJECTED].includes(playlistStatus)) ||
			(isContentCreator() && isViewOnly())
		);
	}

	render() {
		const { song, inFolder } = this.props;

		if (inFolder) {
			return (
				<div className='col-xl-6 col-lg-12 mb-3 pl-0' key={song.song_id}>
					<div className='songslist__table__row d-flex row no-gutters mr-2 justify-content-between'>
						<div className='col-10 align-self-center d-flex no-gutters'>
							<div style={{ flex: '0 0 112px' }}>{this.getPlaySongButton()}</div>
							<div className='ml-2 col-8 align-self-center'>{this.getSongInfo()}</div>
						</div>
						<div className='col-2 align-self-center'>{this.getSongActions()}</div>
					</div>
				</div>
			);
		}

		return (
			<div className='mb-3 pl-0' key={song.song_id}>
				<div className='songslist__table__row d-flex justify-content-between'>
					<div className='mr-1' style={{ width: '150px' }}>
						{this.getPlaySongButton()}
					</div>
					<div className='mr-1 align-self-center' style={{ maxWidth: '27%', minWidth: '22%' }}>
						{this.getSongInfo()}
					</div>
					<div className='align-self-center' style={{ width: '15%' }}>
						{this.getInOutVal()}
					</div>
					<div className='align-self-center' style={{ width: '13%' }}>
						{this.getTransition()}
					</div>
					<div className='align-self-center' style={{ width: '16%' }}>
						{this.getZoneKey()}
					</div>
					<div className='align-self-center' style={{ width: '16%', paddingTop: '1rem' }}>
						{this.getNotes()}
					</div>
					<div className='align-self-center' style={{ width: '16%' }}>
						{this.getSongActions()}
					</div>
				</div>
			</div>
		);
	}
}
SongsRow.propTypes = {
	folders: PropTypes.arrayOf(PropTypes.object),
	handleAddSongToFolder: PropTypes.func,
	handleAddSongToPlaylist: PropTypes.func,
	handleBlacklistSongISRC: PropTypes.func,
	handleBlacklistSongVariisID: PropTypes.func,
	handleFavouriteSong: PropTypes.func.isRequired,
	handleInValChange: PropTypes.func,
	handleOutValChange: PropTypes.func,
	handlePlaySong: PropTypes.func.isRequired,
	handleRemoveSong: PropTypes.func.isRequired,
	handleTransitionChange: PropTypes.func,
	handleUpdateNote: PropTypes.func,
	handleZoneKeyChange: PropTypes.func,
	inFolder: PropTypes.bool,
	inPlaylist: PropTypes.bool,
	isBlacklisted: PropTypes.bool,
	playlistStatus: PropTypes.number,
	playlists: PropTypes.arrayOf(PropTypes.object),
	showClearedDate: PropTypes.bool,
	song: PropTypes.oneOfType([PropTypes.object]).isRequired,
	transitions: PropTypes.arrayOf(PropTypes.object),
	zoneKeys: PropTypes.arrayOf(PropTypes.object),
};

SongsRow.defaultProps = {
	folders: [],
	handleAddSongToPlaylist: null,
	handleAddSongToFolder: null,
	handleInValChange: null,
	handleOutValChange: null,
	handleTransitionChange: null,
	handleUpdateNote: null,
	handleZoneKeyChange: null,
	handleBlacklistSongISRC: null,
	handleBlacklistSongVariisID: null,
	inPlaylist: false,
	inFolder: false,
	isBlacklisted: false,
	playlistStatus: 0,
	playlists: [],
	showClearedDate: false,
	transitions: [],
	zoneKeys: [],
};

SongsRow.contextType = GlobalContext;

export default SongsRow;
